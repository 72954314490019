// Generated by Framer (a387266)

import { addFonts, cx, CycleVariantState, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["AhMiRBP5Q"];

const serializationHash = "framer-3OrB8"

const variantClassNames = {AhMiRBP5Q: "framer-v-1x7fyov"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "AhMiRBP5Q", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"https://apps.apple.com/nl/app/433-the-home-of-football/id1601630546"}><Image {...restProps} as={"a"} background={{alt: "", fit: "fill", intrinsicHeight: 159, intrinsicWidth: 537, pixelHeight: 159, pixelWidth: 537, sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/2DwjgYC96pwXLAT1mB7QsbP9w.png", srcSet: "https://framerusercontent.com/images/2DwjgYC96pwXLAT1mB7QsbP9w.png?scale-down-to=512 512w,https://framerusercontent.com/images/2DwjgYC96pwXLAT1mB7QsbP9w.png 537w"}} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1x7fyov", className, classNames)} framer-xfldr0`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"AhMiRBP5Q"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}/></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-3OrB8.framer-xfldr0, .framer-3OrB8 .framer-xfldr0 { display: block; }", ".framer-3OrB8.framer-1x7fyov { height: 52px; position: relative; text-decoration: none; width: 174px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 52
 * @framerIntrinsicWidth 174
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerBItwwStxt: React.ComponentType<Props> = withCSS(Component, css, "framer-3OrB8") as typeof Component;
export default FramerBItwwStxt;

FramerBItwwStxt.displayName = "App store";

FramerBItwwStxt.defaultProps = {height: 52, width: 174};

addFonts(FramerBItwwStxt, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})